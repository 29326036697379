<template>
  <b-modal
    v-model="showModal"
    centered
    size="md"
    class="position-relative"
    ok-only
    ok-variant="outline-danger"
    ok-title="Close"
    modal-class="modal-danger"
    title="Delete Screen"
  >

    <div>
      <b-table
        ref="refDeleteDevice"
        :busy="spinner"
        class="position-relative"
        :items="devices"
        responsive
        name="delete-device-table"
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
      >

        <template #cell(name)="data">

          <span>{{ data.item.name }}</span>
        </template>
        <template #cell(actions)="data">
          <b-avatar
            variant="danger"
            size="32"
            button
            @click="deleteDevice(data)"
          >
            <feather-icon
              :id="`delete-icon-${data.index}`"
              icon="Trash2Icon"
              size="16"
              variant=""
            />
            <!-- class="cursor-pointer" -->
            <b-tooltip
              title="Delete Image"
              class="cursor-pointer"
              :target="`delete-icon-${data.index}`"
            />

          </b-avatar>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>
<script >
import {
  BModal, BTable, BAvatar, BTooltip,
} from 'bootstrap-vue'
import { showToast, showErrorMessage } from '@/common/global/functions'

export default {
  components: {
    BModal,
    BTable,
    BAvatar,
    BTooltip,
    // BSpinner,
  },
  props: {
    groupId: {
      required: true,
    },
  },
  emits: ['reload-members'],
  data() {
    return {
      tableColumns: [
        { key: 'name' },
        { key: 'actions' },
      ],
      showModal: false,
      spinner: false,
    }
  },
  computed: {
    devices() {
      return this.$store.getters['device/getDevices']
    },
  },

  methods: {
    showDeleteDevice() {
      this.showModal = !this.showModal
    },
    async deleteDevice(device) {
      this.spinner = true
      try {
        const success = await this.$store.dispatch('device/deleteDevice', { macAddress: device.item.mac_address, userHasGroupId: this.$route.params.id })
        if (success) {
          showToast('Device Deleted', 'Device deleted successfully', 'success')
          await this.$store.dispatch('device/getDevices', {
            groupId: this.$route.params.id,
          })
          this.$emit('reload-members')
          this.spinner = false
          this.showDeleteDevice()
        }
      } catch ({
        response: {
          data: {
            statusCode, data,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Device Deletion', 'You are not authorized for this action!', 'danger')
        }
      }
    },
  },
}
</script>
